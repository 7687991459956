import React from 'react';
import Layout from "../../components/Layout";
import NavOne from "../../components/NavOne";
import PageHeader from "../../components/PageHeader";
import WhyShouldContribute from "../../components/about/WhyShouldContribute";
// import MixerAreaTwo from "../../components/MixerAreaTwo";
// import TeamArea from "../../components/TeamArea";
// import FaqArea from "../../components/FaqArea";
// import ServiceArea from "../../components/ServiceArea";
// import ClientsLogoTwo from "../../components/ClientsLogoTwo";
import Footer from "../../components/Footer";

const WhyShouldContributePage = () => {

    return (
        <Layout pageTitle="JCI India Foundation | Why should I Contribute">
            <NavOne />
            <PageHeader title="Why should I Contribute" />
            <WhyShouldContribute />
            <Footer />
        </Layout>
    );
};

export default WhyShouldContributePage;
