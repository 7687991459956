import React from "react";
import aboutImage2 from "../../images/1981-JCI_President.jpg";
import sectionIcon from "../../images/section-icon.png";
const WhyJci = () => {
    return (
        <section className="about-area">
            <div className="container">
                <div className="row">
                    {/* <div className="col-lg-5">
                        <div className="about-semi-img">
                            <img src={aboutImage2} alt="" />
                        </div>
                    </div> */}
                    <div className="col-lg-12">
                        <div className="about-heading">
                            <div className="section-heading">
                                <div className="section-icon">
                                    <img src={sectionIcon} alt="section-icon" />
                                </div>
                                <h2 className="section__title">Why Should I Contribute</h2>
                                {/* <p className="section__meta">why JCI</p> */}
                                <p className="section__desc">
                                    Contributing to this fund gives you the opportunity to activity support the programs of Junior Chamber International, and in so doing, repay the organization for all that it has given to you.
                                </p>
                                <p className="section__desc">
                                    Participation in the Foundation brings deep personal satisfaction that comes from the knowledge that you have done your part to better our world.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <br/> */}
                <div className="row">
                    <div className="col-lg-12">
                        <div className="about-heading">
                            <div className="section-heading">
                                {/* <div className="section-icon">
                                    <img src={sectionIcon} alt="section-icon" />
                                </div>
                                <h2 className="section__title">Why Should I Contribute</h2>
                                <p className="section__meta">why JCI</p> */}
                                <p className="section__desc">
                                    Many individuals contribute to the Foundation, but chapters are also eligible to participate, perhaps by honoring an outgoing president with a Jaycee Foundation Member or Jaycee Foundation Patron donation, for instance.
                                </p>
                                <p className="section__desc">
                                    The honoree receives the same recognition that an individual donor would. Such a gift is an excellent way to thank an outstanding chapter president or a member with a tribute to last a lifetime.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-lg-5">
                        <div className="about-semi-img">
                            <img src={aboutImage2} alt="" />
                        </div>
                    </div> */}
                </div>
                {/* <br/> */}
                <div className="row">
                    {/* <div className="col-lg-5">
                        <div className="about-semi-img">
                            <img src={aboutImage2} alt="" />
                        </div>
                    </div> */}
                    <div className="col-lg-12">
                        <div className="about-heading">
                            <div className="section-heading">
                                {/* <div className="section-icon">
                                    <img src={sectionIcon} alt="section-icon" />
                                </div>
                                <h2 className="section__title">Why Should I Contribute</h2>
                                <p className="section__meta">why JCI</p> */}
                                <p className="section__desc">
                                    It is through programs sponsored by the Foundation’s development fund that JCI India members are encouraged to identify their ambitions and convert them into realistic goals for personal and community development.
                                </p>
                                <p className="section__desc">
                                    It is up to individual members to prove day by day, hour by hour, that they are the leaders of tomorrow. The foundation is dedicated to ensuring that every member has that opportunity.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhyJci;
